import React from 'react'

export const registerFormFields = [
    {
      name: 'basic-info',
      groupLabel:
        '',
      groupLabelClassName: '',
      groupClassName: '',
      fields: [
        {
          className: 'half-width',
          name: 'firstName',
          label: 'First Name',
          type: 'text',
          required: true,
          onValidate: null,
        },
        {
          className: 'half-width',
          name: 'lastName',
          label: 'Last Name',
          type: 'text',
          required: true,
          onValidate: null,
        },
        {
          name: 'email',
          label: 'Email',
          type: 'email',
          required: true,
          onValidate: null,
          disabled: true
        },
      ],
    },
    {
      name: 'billing-info',
      groupLabel: '',
      groupLabelClassName: '',
      groupClassName: '',
      fields: [
        {
          className: 'half-width',
          name: 'city',
          label: 'City',
          type: 'text',
          required: true,
          onValidate: null,
        },
        {
          className: 'half-width',
          name: 'zip',
          label: 'Post Code/Zip',
          type: 'text',
          required: true,
          onValidate: null,
        },
        {
          className: 'half-width',
          name: 'country',
          label: 'Country',
          type: 'select',
          required: true,
          onValidate: null,
        },
        {
          className: 'half-width',
          name: 'state',
          label: 'State',
          type: 'select',
          required: true,
          onValidate: null,
        },
        {
          className: '',
          name: 'phone',
          label: 'Phone',
          type: 'phone',
          disableDropdown: false,
          fill: true,
          onValidate: () => {},
          required: false
        }
      ],
    },
    {
      name: 'password-info',
      groupLabel: (
        <div className="email-info-block">
          <span>Choose a password for your new</span>
          <b> Mana Common </b>
          <span>account</span>
        </div>
      ),
      groupLabelClassName: '',
      groupClassName: '',
      fields: [
        {
          className: 'half-width',
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true,
          onValidate: (value) => {
            if (value.length < 6) {
              return "The password must be at least 6 characters."
            }
          },
        },
        {
          className: 'half-width',
          name: 'confirmPassword',
          label: 'Confirm Password',
          type: 'password',
          required: true,
          onValidate: null,
        },
      ],
    },
]