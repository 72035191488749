import React from 'react'
import Head from '../../components/head/index'
import Layout from '../../components/layout/index'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import { DelegationsContainer } from 'tf-checkout-react'
import Logo from '../../assets/images/mana_footer.png'
import { registerFormFields } from '../../constants/delegation'
import './style.scss'

setTfCheckoutReactConfigs()

const ComplimentaryGuests = () => {
  const onCustomerExistsError = () => {
    window.location.href = '/'
  }
  return (
    <>
      <Head title="Issue Ticket" />
      <Layout>
        <DelegationsContainer
          onCustomerExistsError={onCustomerExistsError}
          classNamePrefix="delegations"
          registerFormFields={registerFormFields}
          logo={Logo}
        />
      </Layout>
    </>
  )
}

export default ComplimentaryGuests
